<template>
    <div class="container">
        <div class="header">
            <img class="logo" src="../assets/images/ailogo.png"/>
            <div class="title">
                <h4>{{interviewInfo.dbName}}</h4>
                <span>（应聘：{{interviewInfo.jobName}}）</span>
            </div>
            <span>面试时间：{{interviewInfo.interviewTime}}</span>
            <div class="timer">
                <img  src="../assets/images/timers.png"/>
                <span>{{time}}</span>
            </div>
        </div>
        
        <div class="Room-root">
            <!-- 房间 -->
            <div class="Room-userCards">
                <div class="UserCard-root" v-if="myVideo">
                    <div class="videoBox" >
                        <video class="UserCard-video" id="myVideo" 
                        autoplay muted playsinline :srcObject.prop="livePusherUrl">
                        </video>
                        <div class="companyName" v-if="myVideo">
                            <div><img src="../assets/images/user.png"/>
                            {{interviewInfo.userName}}（{{interviewInfo.companyName}}）</div>     
                        </div>
                    </div>             
                </div>
                <div class="UserCard-root" v-for="(v,i) in livePlayerList" :key="i" >
                    <div class="videoBox">
                        <video  v-if='v.url' controls   
                        class="UserCard-video UserCard-videos"  
                        playsinline :srcObject.prop="v.url"  autoplay></video>
                        <div class="companyName" v-if='v.url'>
                            <div v-show="interviewInfo.name"><img src="../assets/images/user.png"/>
                            {{interviewInfo.name}}（{{interviewInfo.orgName}}）</div>  
                        </div>
                    </div>
                    
                </div>
            </div>
            <!-- 功能 -->
            <div style="z-index:999;height:54px">
                <div class="ToolBar-root">
                    <!-- 左边工具栏 -->
                    <div class="ToolBar-leftSide">
                        <div title="摄像头" @click="useCamera">
                            <img v-if="Camera" src="../assets/images/camera.30J9I.png"/>
                            <img v-else src="../assets/images/cameraOff.2WccF.png"/>
                        </div>
                        <div title="麦克风" @click="useMic">
                            <img v-if="mic" src="../assets/images/mic.244SV.png"/>
                            <img v-else src="../assets/images/micOff.1mm0C.png"/>
                             
                        </div>
                    </div>
                    <div title="关闭通讯" @click="loginOut">
                        <img src="../assets/images/hangUp.49dRR.png"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {ZegoExpressEngine} from 'zego-express-engine-webrtc'
const appID = 67185206
const server = 'wss://webliveroom-test.zego.im/ws'
let zg;
export default {
    name:"videoTalk",
    data() {
        return {
            time:"00:00:00",
            timer:null, //计时器
            connectType:-1, //房间的状态-1 初始化，1 连接  , 0 断开连接 
            livePlayerList:[],  //其他观众的地址集合
            livePusherUrl:'', //房主的推流地址
            roomID:"",  //房间号
            obj:{        //用户的信息
                userID:"",
                userName:''
            },
            config:{
                userUpdate:true,
                maxMemberCount:2
            },
            token:"", //token
            myVideo:false,
            mic:true,    //open/close  mic
            Camera:true,   //open/close  Camera
            pushConfig: {           // 推流配置项
                mode: 'RTC',
                aspect: '3:4',        // 画面比例，取值为 3:4, 或者 9:16
                minBitrate: 200,      // 最小视频编码码率
                maxBitrate: 500,      // 最大视频编码码率
                isBeauty: 4,          // 美颜程度，取值范围 [0,9]
                isWhiteness: 4,       // 美白程度，取值范围 [0,9]
                muted: false,         // 推流是否静音
                showLog: false,       // 是否显示 log
                frontCamera: true,    // 前后置摄像头，false 表示后置
                enableCamera: true,   // 是否开启摄像头
                isMirror: false,      // 画面是否镜像
                orientation: 'vertical'
            },
            interviewInfo:{}
        }
    },
    methods:{
            // 打开/关闭摄像头
            useCamera(){
                let mute=!this.Camera
                this.Camera=!this.Camera
                zg.mutePublishStreamVideo(this.livePusherUrl,mute)
            },
            useMic(){
                let mute=!this.mic
                this.mic=!this.mic
                zg.mutePublishStreamAudio(this.livePusherUrl,mute)
            },
            
            //退出登录
            async loginOut(){
                                
                this.$allFun.getConfirmBox('结束在线视频').then(async()=>{
                     try {
                            if (this.livePusherUrl) {
                                zg.stopPublishingStream(this.livePusherUrl.id);
                                this.livePusherUrl=''
                                this.myVideo=false
                            }
                            if (this.livePlayerList.length > 0) {
                                    this.livePlayerList.forEach (async (item) => {
                                      item.url=''
                                      zg.stopPlayingStream(item.streamID);
                                    });
                                    this.livePlayerList=[]
                            }
                            /** 登出房间 */
                            if (zg && this.connectType === 1) {
                               await zg.logoutRoom(this.roomID);
                               this.connectType=-1
                               window.close()
                            }
                    } catch(error) {
                            console.error('error: ', error);
                    }
                })
            
            },
            //进入房间
            async goRoom(){ 
                if(!this.roomID){
                    this.$allFun.getPromptBox('没有房间号,请联系美和易思对接人')
                    return
                }
                if(this.connectType==-1){
                
                    try {
                        //获取token
                        let token=await this.getToken()
                        if(!token){
                            return
                        }
                        this.token=token
                        //登录房间
                         await zg.loginRoom(this.roomID,this.token,this.obj,this.config); 
                        
                        this.connectType=1  //改变房间状态
                        if(this.livePusherUrl=== ''){
                            
                            let livePusherUrl= await zg.createStream()  //创建自己的流
                            this.livePusherUrl=livePusherUrl
                            this.myVideo=true
                            this.startPush()
                            
                        }
                        
                    } catch (error) {
                        console.log(error)
                    }
                }
            },
            //开始推流
            async startPush(){
                await zg.startPublishingStream(this.livePusherUrl.id,this.livePusherUrl); 
                    
            },
            //获取拉流获取的播放地址  url===stream
            async setPlayUrl(id,url,streamID){
                if(!url){
                    return
                }
                for (let i = 0; i < this.livePlayerList.length; i++) {
                        if (
                            this.livePlayerList[i]["id"] === id &&
                            this.livePlayerList[i]["url"] === url&&
                            this.livePlayerList[i]["streamID"] === streamID
                        ) {
                                return;
                        }
                }

                let streamInfo = { id: "", url: "" ,streamID:""};
                let isStreamRepeated = false;

                // 相同 id 的源已存在，更新 Url
                for (let i = 0; i < this.livePlayerList.length; i++) {
                        if (this.livePlayerList[i]["id"] === id) {
                                isStreamRepeated = true;
                                this.livePlayerList[i]["url"] = url;
                                this.livePlayerList[i]["streamID"] = streamID;
                                break;
                        }
                }

                // 相同 id 的源不存在，创建新 player
                if (!isStreamRepeated) {
                        streamInfo["id"] = id;
                        streamInfo["url"] = url;
                        streamInfo["streamID"] = streamID;
                        this.livePlayerList.push(streamInfo);
                }
              
            },
            // 有人进入房间，开始拉流 生成房间的LIST
            async playAll(streamList){
                if (streamList.length === 0) {
                    
                    return;
                }
                // 获取每个 streamId 对应的拉流 url
               
                for (let i = 0; i < streamList.length; i++) {
                    /** 开始拉流，返回拉流地址 */
                    try {
                        
                        let stream= await zg.startPlayingStream(streamList[i].streamID);
                       
                        this.setPlayUrl(stream.id,stream,streamList[i].streamID);
                    } catch (error) {
                            console.error("error", error);
                    }
                }
            },
            //有人退出房间，则变化房间里面的video
            async stopPlayAll(streamList){
                if (streamList.length === 0) { 
                    return;
                }
                
                let playStreamList = this.livePlayerList;
                for (let i = 0; i < streamList.length; i++) {
                    let streamID = streamList[i].streamID;
                    zg.stopPlayingStream(streamID);
                    // 把远程被删除的流从播放的流列表中删除
                    for (let j = 0; j < playStreamList.length; j++) {
                            if (playStreamList[j]["streamID"] === streamID) {
                                    playStreamList.splice(j, 1);
                                    break;
                            }
                    }
                }
               
                this.livePlayerList=playStreamList
                
            },  
            //获取token的值
            async getToken(){
                let token=await this.$htp.get_('/token?userId='+this.obj.userID)  
                return token.data
            },
            //初始化zg实例
            async initZg(){
                zg = new ZegoExpressEngine(appID,server)  //初始化一个zg的实例
                
                //拉流是否成功
                zg.on("playerStateUpdate", (result) => {
                    console.log("playStateUpdate", result);
                });
                //房间状态的回调，是否断开连接
                zg.on('roomStateUpdate', (roomID,state) => {
                   
                    if (state == 'DISCONNECTED') {
                        // 与房间断开了连接
                        this.connectType=-1
                        this.$allFun.getPromptBox(`与房间断开了连接`,"info")
                    }

                    if (state == 'CONNECTING') {
                        // 与房间尝试连接中 
                        this.$allFun.getPromptBox(`正在连接房间`,"info")
                    }

                    if (state == 'CONNECTED') {
                        // 与房间连接成功
                        this.$allFun.getPromptBox(`房间连接成功`,"success")
                    }
                }) 
                //房间用户的更新
                zg.on('roomUserUpdate',(roomID,updateType, userList)=>{
                    let nickName=userList[0].userName
                    if(updateType=='ADD'){
                        this.$allFun.getPromptBox(`${nickName}进入房间`,"success")
                    }else{
                        this.$allFun.getPromptBox(`${nickName}离开房间`,"info")
                    }
                })
                //房间流更新的回调
                zg.on('roomStreamUpdate',(roomID,type,streamList)=>{
                       
                        if(type==='ADD'){
                            this.playAll(streamList)
                        }else{
                            this.stopPlayAll(streamList)
                        }
                        
                })
                //推流状态变更通知
                zg.on("publisherStateUpdate", (result) => {
                    console.log("publishStateUpdate", result);
                });
                //推流质量
                zg.on("publishQualityUpdate", (streamID, publishStats) => {
                    console.log("publishQualityUpdate", streamID, publishStats);
                });
                
                
                this.goRoom()
                
            },
            //开始计时
            startTimer(){
                let second=0,minute=0,hour=0
                this.timer=setInterval(()=>{
                     second++;
                    if(second>=60){
                        second=0;
                        minute=minute+1;
                    }
                
                    if(minute>=60){
                        minute=0;
                        hour=hour+1;
                    }
                    this.time=`${this.formatTime(hour)}:${this.formatTime(minute)}:${this.formatTime(second)}`
                },1000)
            },
            formatTime(time){
                return time<10?`0${time}`:`${time}`
            },

        },
    mounted(){
         let interviewInfo=sessionStorage.getItem('interviewInfo')
        if(!interviewInfo)return
        this.interviewInfo=JSON.parse(interviewInfo)

        let roomID=this.$route.query.id 
        if(!roomID){
            this.$allFun.getPromptBox('没有创建房间号，请联系对接人')
            return
        }
        this.roomID=roomID

        let nickName=sessionStorage.getItem('nickName')||'游客'+(+new Date())
        let id=sessionStorage.getItem('id')
        if(!id){
            this.$allFun.getPromptBox('未识别您的身份,请从平台登录')
            return
        }
        this.obj.userID=String(id)
        this.obj.userName=String(nickName)
       
        
        this.initZg()
        this.startTimer()
        
    },
    destroyed(){
        window.clearInterval(this.timer)
        this.time="00:00:00"
    }
}
</script>
<style lang="scss" scoped>
@import '../assets/css/videoTalk.scss';
</style>